// benefitsData.js

import vector from "../Vector@2x.png"
const benefitsData = [
    {
      title: 'Compliance',
      description: 'Meet regulatory and compliance requirements for data retention, and other industry-specific regulations.',
      img: vector,
    },
    {
      title: 'Data Security',
      description: 'Advanced encryption and access controls protect sensitive data from unauthorized access & breaches.',
      img: vector,
    },
    {
      title: 'Scalability',
      description: 'Easily scale storage capacity to keep growing data volumes without significant capital investment.',
      img: vector,
    },
    {
      title: 'Cost Efficiency',
      description: 'Competitive pricing models reduce the total cost of ownership compared to on-premises storage solutions.',
      img: vector,
    },
    {
        title: 'Accessibility',
        description: 'Secure access to data from anywhere, at any time, ensuring that organizations can retrieve and use their data as needed.',
        img: vector,
      }
  ];
  
  export default benefitsData;
  