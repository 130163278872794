import React, { useEffect, useState } from 'react';
import { FaLessThan, FaGreaterThan } from "react-icons/fa";
import Aos from 'aos';
import 'aos/dist/aos.css';
import benefitsData from '../assets/data/benefitsData';

const Hero = () => {
  const [currentBenefit, setCurrentBenefit] = useState(0);
  const [autoSlideInterval, setAutoSlideInterval] = useState(null); // Store the interval ID

  // Function to go to the next benefit
  const nextBenefit = () => {
    setCurrentBenefit((previousBenefit) => (previousBenefit + 1) % benefitsData.length);
  };

  // Function to go to the previous benefit
  const previousBenefit = () => {
    setCurrentBenefit((previousBenefit) => (previousBenefit - 1 + benefitsData.length) % benefitsData.length);
  };

  // Start the automatic slider
  useEffect(() => {
    // Initialize AOS animation
    Aos.init({ duration: 3000 });

    // Set up the auto-slide interval
    const interval = setInterval(() => {
      nextBenefit();
    }, 3000); // Change every 3 seconds

    setAutoSlideInterval(interval);

    // Cleanup on unmount or when dependencies change
    return () => clearInterval(interval);
  }, []);

  // Handle manual navigation (stop auto slide when user clicks)
  const handleManualNavigation = () => {
    if (autoSlideInterval) {
      clearInterval(autoSlideInterval); // Stop the auto slide interval
      setAutoSlideInterval(null); // Reset the interval ID
    }

    //Restart the auto slider
    const interval= setInterval(()=>{
      nextBenefit();
    },3000);
    setAutoSlideInterval(interval)
  };

  return (
    <div className='lg:h-screen h:screen pl-10 pr-10 lg:flex-row flex-col md:flex-col gap-8 lg:pl-20 lg:pr-20 2xl:h-screen 2xl:pl-60 2xl:pr-60 flex 2xl:gap-14 lg:w-full lg:gap-4' data-aos="fade-up" data-aos-offset="50">
      <div className='lg:h-dataRent h-1/2 p-10 flex flex-col gap-4 md:p-20 md:h-1/2 md:w-full pt-6 rounded-3xl lg:pt-6 bg-heroRent lg:w-dataRent pl-10 pr-10 lg:pl-16 lg:pr-16' data-aos="flip-left">
        <h3 className='md:text-dataRent text-2xl text-center leading-snug md:text-start font-bold text-white'>Data Retention Services</h3>
        <p className='text-dataRentp'>Andurar Cloud's Data Retention Service ensures that critical business data is stored securely and remains accessible to authorized persons when needed. We provide flexible and compliant data storage solutions, ensuring your data is protected, retrievable, and meets regulatory standards for long-term storage.</p>
      </div>
      <div className='bg-keysColor flex flex-col gap-4 h-1/2 p-10 md:p-20 w-full md:h-1/2 md:w-full pt-6 rounded-3xl lg:h-dataRent bg-heroRent lg:w-dataRent lg:pt-6 pl-10 pr-10 lg:pl-16 lg:pr-16' data-aos="flip-right">
        <h3 className='text-white leading-snug text-2xl text-center md:text-start font-bold md:text-dataRent'>Benefits</h3>
        <div className='bg-keysColorOne border-2 border-heroBorder rounded-3xl flex flex-col gap-4 p-10 lg:pt-10 lg:pb-16 lg:pl-16 lg:pr-16'>
          <img 
            src={benefitsData[currentBenefit].img} 
            alt="icons" 
            className='lg:h-vectorHeight h-mdvectorHeight w-mdvectorWidth lg:w-vectorWidth' 
          />
          <h4 className='text-white text-xl'>{benefitsData[currentBenefit].title} </h4>
          <p className='text-dataRentp text-xl'>{benefitsData[currentBenefit].description} </p>
        </div>
        <div className='flex gap-4 justify-center'>
          <button 
            className='bg-keysColorOne h-10 w-10 rounded-3xl flex justify-center items-center' 
            onClick={() => { handleManualNavigation(); previousBenefit(); }}
          >
            <FaLessThan className='text-dataRentp' />
          </button>
          <button 
            className='bg-keysColorOne h-10 w-10 rounded-3xl flex justify-center items-center' 
            onClick={() => { handleManualNavigation(); nextBenefit(); }}
          >
            <FaGreaterThan className='text-dataRentp' />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Hero;
