import coin from "../coin.png";
import vec from "../vec.png";
import dat from "../dat.png";
import vector from "../Vector@2x.png";
import encry from "../encry.png";
import money from "../carbon_money.png";

const keyFeatData=[
    {
        img: coin,
        title: "Robust Data Protection",
        description: "Safeguard your data with regular backups."
    },
    {
        img: vec,
        title: "Effortless Integration",
        description: "Seamless integration with existing infrastructure and development tools."
    },
    {
        img: dat,
        title: "Dedicated Support",
        description: "24/7 technical support to assist with environment setup, troubleshooting, and optimization."
    },
    {
        img: vector,
        title: "Custom Configurations",
        description: "Tailored cloud environment with specific operating systems, applications, and network settings."
    },
    {
        img: encry,
        title: "User-friendly Interface",
        description: "Easily manage, configure, and monitor cloud environments and resources with an intuitive interface."
    },
    {
        img: money,
        title: "On-Demand resource provisioning",
        description: "Instantly allocate and access computing resources - such as CPU and memory —when needed"
    }
]

export default keyFeatData;