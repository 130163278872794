import coin from "../coin.png";
import vec from "../vec.png";
import dat from "../dat.png";
import vector from "../Vector@2x.png";
import encry from "../encry.png";
import money from "../carbon_money.png";
const dataRetention=[
    {
        img: coin,
        title: "Multi-Tenancy",
        description: "Isolated storage environments for each customer using Storage Virtual Machines (SVMs)."
    },
    {
        img: vec,
        title: "High Availability",
        description: "Redundant infrastructure and disaster recovery solutions to ensure data availability and integrity"
    },
    {
        img: dat,
        title: "Data Management Tools",
        description: "Comprehensive tools for data backup, archiving, and lifecycle management."
    },
    {
        img: vector,
        title: "Compliance Reporting",
        description: "Automated compliance reporting to help organizations demonstrate adherence to regulatory requirements"
    },
    {
        img: encry,
        title: "Encryption and Security",
        description: "Data encryption at rest and in transit, with robust access controls and audit logging."
    },
    {
        img: money,
        title: "Cost saving",
        description: "Meet regulatory and compliance requirements for data retention, and other industry-specific regulations"
    }
]

export default dataRetention;