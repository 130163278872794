import React, { useEffect } from 'react'
import 'aos/dist/aos.css';
import Aos from 'aos';
import money from "../assets/carbon_money.png";

const WhyUs = () => {
    useEffect(()=>{
        Aos.init({duration:3000});
    },[])
  return (
    <div className='lg:pl-20 p-10 lg:mt-6 lg:pr-20 2xl:pr-60 2xl:pl-60'  data-aos="fade-up" data-aos-offset="50">
        <div className='flex flex-col p-10 lg:flex-col 2xl:pl-20 2xl:pr-20 bg-keysColor rounded-3xl justify-center lg:pl-16 lg:pr-16 lg:pb-16' data-aos="flip-up">
      <h3 className='text-white md:text-dataRent leading-snug text-2xl font-bold text-center'>Why choose Andurar Cloud Service?</h3>
      <div className='flex lg:flex-row md:flex-col gap-4 p-4 flex-col 2xl:gap-10 2xl:pt-6 lg:pr-6 lg:pl-6'>
        <div className='lg:w-whyUsWidth flex lg:flex-col p-4 flex-col gap-4 lg:pl-6 lg:pr-6 lg:pt-6 rounded-3xl lg:h-whyUsHeight bg-keysColorOne border-2 border-heroBorder' data-aos="fade-right">
        <img src={money} alt="" className='w-mdvectorWidth h-mdvectorHeight lg:h-vectorHeight lg:w-vectorWidth' />
            <h3 className='font-semibold text-white md:text-4xl'>Proven Expertise</h3>
            <p className='text-base text-dataRentp'>With years of experience in cloud technology, we provide tailored solutions to fit your specific business needs.</p>
        </div>
        <div className='lg:w-whyUsWidth p-4 flex flex-col lg:flex-col gap-4 lg:pl-6 lg:pr-6 lg:pt-6 rounded-3xl lg:h-whyUsHeight bg-keysColorOne border-2 border-heroBorder' data-aos="fade-left">
        <img src={money} alt="" className='w-mdvectorWidth h-mdvectorHeight lg:h-vectorHeight lg:w-vectorWidth' />
            <h3 className='font-semibold text-white md:text-4xl'>24/7 Support</h3>
            <p className='text-base text-dataRentp'>Our dedicated team of experts is available around the clock to ensure your infrastructure runs smoothly.</p>
        </div>
        <div className='lg:w-whyUsWidth p-4 flex flex-col lg:flex-col gap-4 lg:pl-6 lg:pr-6 lg:pt-6 rounded-3xl lg:h-whyUsHeight bg-keysColorOne border-2 border-heroBorder' data-aos="fade-up">
        <img src={money} alt="" className='w-mdvectorWidth h-mdvectorHeight lg:h-vectorHeight lg:w-vectorWidth' />
            <h3 className='font-semibold text-white md:text-4xl'>Flexible Pricing</h3>
            <p className='text-base text-dataRentp'>Choose from various pricing plans that offer flexibility and scalability, ensuring you get the most value from your investment.</p>
        </div>
        <div className='lg:w-whyUsWidth p-4 flex flex-col lg:flex-col gap-4 lg:pl-6 lg:pr-6 lg:pt-6 rounded-3xl lg:h-whyUsHeight bg-keysColorOne border-2 border-heroBorder' data-aos="fade-down">
        <img src={money} alt="" className='w-mdvectorWidth h-mdvectorHeight lg:h-vectorHeight lg:w-vectorWidth' />
            <h3 className='font-semibold text-white md:text-4xl'>Enterprise-Grade Security</h3>
            <p className='text-base text-dataRentp'>Your data is protected by advanced security measures,
            ensuring that ti stays safe from unauthorized access.</p>
        </div>
      </div>
    </div>
    </div>
  )
}

export default WhyUs
