import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import "./Demo.css"
import { toast } from 'react-toastify';
import { FiArrowUpRight } from 'react-icons/fi';

const Demo = React.forwardRef((props, ref) => {
  const [formData, setFormData] = useState({
    name: '',
    organization: '',
    email: '',
    phone: '',
  });

  const [isLoading, setLoading]= useState(false);
  const [showMessage, setShowMessage]= useState(false);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
     // Only allow numeric values and limit to 10 characters
  if (name === "phone") {
    const numericValue = value.replace(/[^0-9]/g, '').slice(0, 10); // Allow only numbers and limit to 10
    setFormData({
      ...formData,
      [name]: numericValue,
    });
  } else {
    setFormData({
      ...formData,
      [name]: value,
    });
  }
};

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        formData,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (response) => {
          console.log('Email sent successfully', response);
          setLoading(false);
          setShowMessage(true);
          toast.success('Thank you! Your request has been submitted.');
        },
        (error) => {
          console.log('Error sending email', error);
          setLoading(false);
          toast.error('Oops! Something went wrong, please try again.');
        }
      );

    // Optionally clear the form after submission
    setFormData({
      name: '',
      organization: '',
      email: '',
      phone: '',
    });
  };

  return (
    <div className="lg:h-screen h-auto bg-keysColor p-10 2xl:pr-60 2xl:pl-60 lg:pl-20 lg:pt-20 mt-10 lg:pr-20" ref={ref}>
      <div className="lg:h-full flex md:flex-col lg:flex-row p-4 flex-col gap-10 rounded-3xl">
        <div className="lg:w-1/2 lg:pl-20 lg:pr-20">
          <h3 className="md:text-dataRent font-bold text-2xl leading-snug text-center md:text-start text-white">Request a Demo</h3>
          <p className="text-base text-dataRentp lg:pr-20">
          Schedule a personalized demo with our experts. Request a demo today and let Andurar simplify your journey to the cloud with tailored and  seamless solutions.
          </p>
        </div>
        <div className="lg:w-1/2 flex flex-col lg:flex-col p-10 gap-4 lg:h-auto mt-8 rounded-3xl lg:mt-4 lg:pl-20 bg-demoBg lg:pt-10 lg:pr-20">
          <div className="flex flex-col lg:flex-col gap-4">
            <h4 className="text-xl font-bold text-black">Request a Demo</h4>
            <p className="text-base text-demoFo">
              Schedule a personalized demo with one of our experts today.
            </p>
          </div>
          <form onSubmit={handleSubmit} className="flex flex-col lg:flex-col gap-4 items-start">
            <label htmlFor="name" className="font-semibold text-lg">
              Name *
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="lg:w-full w-full h-14 rounded-xl lg:pl-4 lg:h-14"
            />
            <label htmlFor="organization" className="font-semibold text-lg">
              Organization *
            </label>
            <input
              type="text"
              name="organization"
              value={formData.organization}
              onChange={handleChange}
              className="lg:w-full w-full h-14 lg:h-14 lg:pl-4 rounded-xl"
            />
            <div className="flex w-full lg:flex-row flex-col lg:w-full gap-4">
              <div className="lg:w-1/2 flex-col flex lg:flex-col gap-4">
                <label htmlFor="email" className="font-semibold text-lg">
                  Email *
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full h-14 lg:pl-4 rounded-xl text-center"
                />
              </div>
              <div className="lg:w-1/2 w-full flex-col flex lg:flex-col gap-4">
                <label htmlFor="phone" className="font-semibold text-lg">
                  Phone Number *
                </label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full h-14 placeholder:text-xs placeholder:text-center pl-4 rounded-xl lg:pl-4 lg:pr-4"
                  placeholder="Mobile Phone e.g 0244000001"
                />
              </div>
            </div>
            <button type="submit" className="bg-primary w-full h-14 justify-center items-center lg:w-44 flex text-white lg:justify-center lg:items-center font-bold lg:h-14 rounded-3xl">
              <p>Submit</p>
              <FiArrowUpRight />
            </button>
          </form>
          {/* Show Loading Animation when submitting */}
          {isLoading && (
            <div className="lg:mt-2">
              <div className="spinner"></div> {/* Replace this with your custom animation */}
            </div>
          )}

          {/* Show success message after submission */}
          {showMessage && !isLoading && (
            <div className="lg:mt-2 text-successMessage text-base text-demoFo">
              <p>Thank you! We've received your request successfully. An agent will contact you shortly.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default Demo;
