import React, { useEffect, useState } from 'react'
import Aos from 'aos';
import { FaLessThan } from "react-icons/fa";
import { FaGreaterThan } from "react-icons/fa";
import cloudBenefits from '../assets/data/cloudBenefits';
import cloudService from '../assets/data/cloudService';
import 'aos/dist/aos.css';

const CloudComputing = () => {
  const [currentBenefit, setCurrentBenefit]= useState(0);
  const [autoSlideInterval, setAutoSlideInterval]= useState(null);

  const nextBenefit= ()=>{
    setCurrentBenefit((previousBenefit)=> (previousBenefit+1) % cloudBenefits.length);
  }

  const previousBenefit=()=>{
    setCurrentBenefit((previousBenefit)=>(previousBenefit -1 + cloudBenefits.length) % cloudBenefits.length);
  }

    useEffect(()=>{
        Aos.init({duration:3000});

        //Set up auto slider
        const interval = setInterval(()=>{
          nextBenefit();
        },3000); // Change after every 3 seconds

        setAutoSlideInterval(interval);

        return ()=> clearInterval(interval);
    },[]);

    // Handle manual navigations
    const handleManualNavigation=()=>{
      if(autoSlideInterval){
        clearInterval(autoSlideInterval); // Stop the auto slide interval
        setAutoSlideInterval(null);
      }

      //Restart the auto slider
      const interval= setInterval(()=>{
        nextBenefit();
      },3000);
      setAutoSlideInterval(interval)
    }
    
  return (
   <div className='lg:h-screen h:screen pl-10 pr-10 lg:flex-row flex-col md:flex-col gap-8 lg:pl-20 lg:pr-20 2xl:h-screen 2xl:pl-60 2xl:pr-60 flex 2xl:gap-14 lg:w-full lg:gap-4'  data-aos="fade-up" data-aos-offset="50">
      <div className='lg:h-dataRent h-1/2 p-10 flex flex-col gap-4 md:p-20 md:h-1/2 md:w-full pt-6 rounded-3xl lg:pt-6 bg-heroRent lg:w-dataRent pl-10 pr-10 lg:pl-16 lg:pr-16' data-aos="flip-right">
        <h3 className='md:text-dataRent leading-snug text-2xl font-bold text-white md:text-start text-center'>{cloudService[0].title} </h3>
        <p className='text-cloudText'>{cloudService[0].description} </p>
      </div>
      <div className='bg-keysColor flex flex-col gap-4 h-auto rounded-3xl p-10 lg:h-dataRent bg-heroRent lg:w-dataRent lg:pt-6 lg:pl-16 lg:pr-16' data-aos="flip-down">
      <h3 className='text-white text-2xl md:text-dataRent leading-snug md:text-start text-center font-bold'>Benefits</h3>
        <div className='bg-keysColorOne border-2 rounded-3xl p-4 border-heroBorder flex flex-col gap-4 lg:pt-10 lg:pb-16 lg:pl-16 lg:pr-16'>
            <img src={cloudBenefits[currentBenefit].img} alt="icons" className='h-mdvectorHeight w-mdvectorWidth lg:h-vectorHeight lg:w-vectorWidth' />
            <h4 className='text-white text-xl'>{cloudBenefits[currentBenefit].title}</h4>
            <p className='text-dataRentp text-xl'>{cloudBenefits[currentBenefit].description}</p>
        </div>
        <div className='flex gap-4 justify-center pb-20'>
          <button className='bg-keysColorOne h-10 w-10 rounded-3xl flex justify-center items-center' onClick={() => { handleManualNavigation(); previousBenefit(); }}>
          <FaLessThan className='text-dataRentp' />
          </button>
          <button className='bg-keysColorOne h-10 w-10 rounded-3xl flex justify-center items-center' onClick={() => { handleManualNavigation(); nextBenefit(); }}>
          <FaGreaterThan className='text-dataRentp' />
          </button>
        </div>
      </div>
   </div>
  )
}

export default CloudComputing
