// benefitsData.js

import vector from "../Vector@2x.png"
const cloudBenefits = [
    {
      title: 'Efficiency',
      description: 'Rapid provisioning of environments reduces setup time, accelerating development cycles and time-to-market for new products.',
      img: vector,
    },
    {
      title: 'Security',
      description: 'Isolated environments ensure that data and applications remain secure, with no risk of cross-contamination between projects.',
      img: vector,
    },
    {
      title: 'Scalability',
      description: 'Customers can quickly scale their test environments up or down based on their project requirements, ensuring optimal resource utilization.',
      img: vector,
    },
    {
      title: 'Cost Efficiency',
      description: 'Pay-as-you-go pricing models allow customers to only pay for the resources they use, reducing capital expenditure on hardware and infrastructure.',
      img: vector,
    },
    {
        title: 'Flexibility',
        description: 'Multiple configurations and environments can be created to suit different testing needs, from application development to stress testing.',
        img: vector,
      }
  ];
  
  export default cloudBenefits;
  