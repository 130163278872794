import { useEffect, useState, useRef } from 'react';
import './App.css';
import Header from './Components/Header';
import Hero from './Components/Hero';
import KeyFeatures from './Components/KeyFeatures';
import CloudComputing from './Components/CloudComputing';
import KeyFeat from './Components/KeyFeat';
import WhyUs from './Components/WhyUs';
import Footer from './Components/Footer';
import Demo from './Components/Demo';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [loading, setLoading] = useState(true);
  const demoRef = useRef(null);  // Create a ref for Demo component

  // Function to scroll to the Demo component
  const handleBookDemo = () => {
    if (demoRef.current) {
      demoRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error('demoRef is not set properly');
    }
  };

  // For loading a page
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="bg-main-gradient from-bgColorTwo flex flex-col to-bgMainColor">
      {/* Pass handleBookDemo to Header */}
      <Header handleBookDemo={handleBookDemo} />
      <Hero />
      <KeyFeatures />
      <CloudComputing />
      <KeyFeat />
      <WhyUs />
      {/* Pass demoRef to Demo */}
      <Demo ref={demoRef} />
      <Footer />

      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
