import React, { useEffect } from 'react';
import vectee from "../assets/vecteezy_business-by-cloud-computing-technology-for-business_5084479 1.png";
import Aos from 'aos';
import 'aos/dist/aos.css';

const Header = ({ handleBookDemo }) => {

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className='lg:h-screen 2xl:h-screen pl-10 pr-10 h-screen 2xl:pl-60 2xl:pr-60 lg:w-full lg:pl-20 lg:pr-20 flex flex-col md:flex-row lg:flex-row gap-6 items-center' data-aos="fade-up" data-aos-offset="50">
      <div className='flex lg:pt-20 xl:pt-40 pt-10 xl:gap-8 gap-4 lg:gap-6 flex-col'>
        <h3 className='text-5xl text-white'>Andurar Cloud Services</h3>
        <p className='text-headerTxtColor'>Secure, scalable cloud solutions for your business</p>
        <div className='gap-4 flex lg:flex-row flex-col'>
          <button
            className='lg:w-64 h-16 lg:h-16 lg:text-xl text-xl bg-bgColor rounded-3xl text-white'
            onClick={handleBookDemo}  // Trigger scroll on click
          >
            Book a Demo
          </button>
          <button className='text-white h-16 rounded-3xl lg:w-norma bg-bgColorThree border-2 border-heroBorder text-xl lg:text-xl lg:h-16' onClick={handleBookDemo}>
            Contact an ACS Specialist
          </button>
        </div>
      </div>
      <div className='animate-bounceUpDown'>
        <img src={vectee} alt="business cloud" />
      </div>
    </div>
  );
};

export default Header;
